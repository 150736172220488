/* Estilo para Tendências */
.overbought {
    color: #4caf50; /* Verde */
    font-weight: bold;
  }
  
  .oversold {
    color: #f44336; /* Vermelho */
    font-weight: bold;
  }
  
  .neutral {
    color: #ffc107; /* Amarelo */
    font-weight: bold;
  }
  